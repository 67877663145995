.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* clients section  */

.clients {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.clientscontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clientsleft {
  width: 100%;
  display: flex;
  align-items: center;
}

.textwrapper {
  width: 210px;
  margin-left: 12px;
  margin-top: 31px;
  border-right: 2px solid #ffffff;
}

.clientstext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
}

.buttonwrapper {
  margin-left: 26px;
  margin-top: 25px;
  width: 105px;
  height: 75px;
  border-right: 2px solid #ffffff;
}

.clientsaddbtn {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 600;
  line-height: 1px;
  color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  text-transform: uppercase;
  margin-top: 40px;
  margin-right: 10px;
}

.searchwrapper {
  max-width: 1283px;
  width: 100%;
  margin-left: 18px;
  margin-right: 18px;
}

.searchbar {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 400;
  line-height: 55px;
  color: #0f1521;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #616161;
  border: none;
  background-image: url("../../assets/clientspageimages/search.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.searchbar:focus {
  background-image: none;
  background-color: #ffffff;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

.clientsright {
  position: relative;
}

/* clients info section  */

.accordion {
  max-width: 1920px;
  overflow: hidden;
  background-color: #0f1521;
}

.accordionsection {
  margin-top: 2px;
}

.accordionlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  background-color: #444444;
}

.accordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
  width: 24px;
  height: 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.accordioncontent {
  display: none;
}

.accordioninput {
  display: none;
}

.accordioninput:checked ~ .accordioncontent {
  display: block;
}

.accordioninput:checked ~ .accordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* client  */

.clientinfo {
  display: flex;
  align-items: center;
}

.clientstatus {
  display: flex;
  align-items: center;
}

.clientstatustext {
  height: 100px;
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  background-color: #ffffff;
  color: #0f1521;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-align: center;
}

.clienttext {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.clientname {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.clienttype {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
}

.clientbuttons {
  display: flex;
  align-items: center;
  margin-right: 120px;
}

.deletebtn,
.editbtn {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

.mr {
  margin-right: 12px;
}

.updatestatus {
  background-color: #2a2a2a;
  border-radius: 50%;
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
  color: #ffffff;
  padding: 10px 21px;
  margin-right: 13px;
}

/* inner accordions  */

.inneraccordion {
  max-width: 1920px;
  overflow: hidden;
}

.inneraccordionlabel {
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  height: 60px;
  background-color: #646464;
}

.inneraccordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.inneraccordioncontent {
  background-color: #272c37;
  display: none;
  height: 180px;
}

.inneraccordioninput {
  display: none;
}

.inneraccordioninput:checked ~ .inneraccordioncontent {
  display: block;
}

.inneraccordioninput:checked ~ .inneraccordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* list  */

.listtext {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 600;
  line-height: 55px;
  color: #ffffff;
  margin-left: 35px;
}

/* person  */

.personinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.persondetails {
  display: flex;
  align-items: center;
}

.persontext {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.personname {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
}

.personposition {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
}

.deletebtnperson {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 131px;
}

/* contract */

.contractinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.contractdetails {
  display: flex;
  flex-direction: column;
  margin-left: 37px;
}

.contractname {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
}

.contractdate {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
}

.contractitems {
  display: flex;
}

.status {
  display: inline-block;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  background-color: #1e222b;
  color: #ffffff;
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  text-transform: uppercase;
  text-align: center;
  margin-right: 38px;
}

/* filter form  */

.filterformcontent {
  position: absolute;
  top: 76px;
  left: -513px;
  background-color: #b4eb54;
  min-width: 625px;
  height: 360px;
  z-index: 1;
  border-radius: 0px 0px 0px 5px;
}

.filterform {
  margin-top: 24px;
}

.formtext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #0f1521;
}

.formwrapper {
  display: flex;
  align-items: center;
}

.formcheckboxtext {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #0f1521;
}

.formcheckbox {
  width: 30px;
  height: 30px;
}

.formoption {
  width: 446px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.formml {
  margin-left: 20px;
}

.formtextml {
  margin-left: 10px;
}

.formtypeml {
  margin-left: 25px;
}

.formmarkml {
  margin-left: 20px;
}

.formcountryml {
  margin-left: 55px;
}

.applybtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  color: #0f1521;
  width: 180px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-left: 218px;
}

/* add form  */

.addformcontent {
  position: absolute;
  top: 182px;
  left: 0px;
  background-color: #b4eb54;
  min-width: 600px;
  height: 814px;
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
}

.addformtext {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  color: #0f1521;
}

.addformoption {
  width: 532px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.addformlisttext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  color: #0f1521;
}

.addformwrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 29px;
}

.addformbtn {
  width: 532px;
  height: 50px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #d2f398;
}

.addbtn {
  width: 180px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-top: 222px;
  margin-left: 199px;
}

.addformmt {
  margin-top: 20px;
}

input::placeholder {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  color: #0f1521;
}

@media only screen and (max-width: 1200px) {
  .clientscontent {
    justify-content: flex-start;
  }

  .clientstext,
  .clientsaddbtn {
    font-size: 30px;
  }

  .filterbtn {
    margin-left: 40px;
  }
}
