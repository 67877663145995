.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* matches section  */

.matches {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.matchescontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.matchesleft {
  width: 100%;
  display: flex;
  align-items: center;
}

.matcheswrapper {
  margin-left: 25px;
  padding-top: 40px;
}

.matchestext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
}

.searchwrapper {
  max-width: 1283px;
  width: 100%;
  margin-left: 180px;
  margin-right: 20px;
  margin-top: -13px;
}

.searchbar {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 400;
  line-height: 55px;
  color: #0f1521;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #616161;
  border: none;
  background-image: url("../../assets/matchespageimages/search.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.searchbar:focus {
  background-image: none;
  background-color: #ffffff;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

.matchesright {
  position: relative;
}

/* table section  */

.infotable {
  border-style: solid;
  border-width: 2px 1px 1px 1px;
  border-color: #000000;
  width: 100%;
}

.matchesth {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  background-color: #3f444d;
  height: 80px;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  border: solid 1px #000000;
  text-transform: uppercase;
}

.matchestd {
  position: relative;
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  height: 80px;
  background-color: #272c37;
  text-align: center;
  vertical-align: middle;
  border: solid 1px #000000;
  color: #ffffff;
}

tr:hover td {
  background-color: #9fa1a6;
}

.editbtn {
  position: absolute;
  top: 7px;
  left: 80%;
  width: 21px;
  height: 21px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #c5c9d0;
  border-radius: 5px;
}

.editimg {
  width: 15px;
  height: 21px;
}

/* filter form  */

.filterformcontent {
  position: absolute;
  top: 72px;
  left: -606px;
  background-color: #b4eb54;
  min-width: 718px;
  height: 352px;
  padding: 12px 16px;
  z-index: 1;
  border-radius: 0px 0px 0px 5px;
}

.filterform {
  margin-top: 10px;
}

.formtext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #0f1521;
}

.periodyear {
  width: 100px;
  height: 50px;
  border-radius: 5px;
  border: none;
}

.periodother {
  width: 55px;
  height: 50px;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
}

.dash {
  margin-left: 15px;
  margin-right: 15px;
}

.forminput {
  width: 476px;
  height: 50px;
  border-radius: 5px;
  border: none;
}

.formml {
  margin-left: 21px;
}

.formperiodml {
  margin-left: 97px;
}

.formteamml {
  margin-left: 108px;
}

.formcompml {
  margin-left: 34px;
}

.formpriorml {
  margin-left: 89px;
}

.savebtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  color: #0f1521;
  width: 180px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-left: 271px;
}

@media only screen and (max-width: 1200px) {
  .matchescontent {
    justify-content: flex-start;
  }

  .matchestext {
    font-size: 30px;
  }

  .searchwrapper {
    margin-left: 30px;
  }

  .filterbtn {
    margin-left: 20px;
  }

  .matchestd,
  .matchesth {
    font-size: 12px;
  }

  .filterformcontent {
    min-width: 690px;
    left: -590px;
  }
}
