.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* contracts section  */

.contracts {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.contractscontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contractsleft {
  width: 100%;
  display: flex;
  align-items: center;
}

.textwrapper {
  width: 210px;
  margin-left: 16px;
  margin-top: 31px;
  border-right: 2px solid #ffffff;
}

.contractstext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
  margin-right: 10px;
}

.buttonwrapper {
  margin-left: 18px;
  margin-top: 25px;
  width: 115px;
  height: 75px;
  border-right: 2px solid #ffffff;
}

.contractsaddbtn {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 600;
  line-height: 1px;
  color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  text-transform: uppercase;
  margin-top: 40px;
  margin-right: 10px;
}

.searchwrapper {
  max-width: 1283px;
  width: 100%;
  margin-left: 18px;
  margin-right: 18px;
}

.searchbar {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 400;
  line-height: 55px;
  color: #0f1521;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #616161;
  border: none;
  background-image: url("../../assets/contractspageimages/search.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.searchbar:focus {
  background-image: none;
  background-color: #ffffff;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

/* contracts info section  */

.accordion {
  max-width: 1920px;
  overflow: hidden;
  background-color: #0f1521;
}

.accordionsection {
  margin-top: 2px;
}

.accordionlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  background-color: #444444;
}

.accordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
  width: 24px;
  height: 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.accordioncontent {
  background-color: #272c37;
  display: none;
}

.accordioninput {
  display: none;
}

.accordioninput:checked ~ .accordioncontent {
  display: block;
}

.accordioninput:checked ~ .accordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* client  */

.clientinfo {
  display: flex;
  align-items: center;
}

.clientstatus {
  display: flex;
  align-items: center;
}

.clientstatustext {
  height: 100px;
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  background-color: #ffffff;
  color: #0f1521;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-align: center;
}

.clienttext {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.clientname {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.clienttype {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
}

/* contract */

.contractinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.contractdetails {
  display: flex;
  flex-direction: column;
  margin-left: 102px;
}

.contractname {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
}

.contractdate {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
}

.seecontractbtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  width: 240px;
  height: 36px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #272c37;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin-right: 120px;
}

@media only screen and (max-width: 1200px) {
  .contractscontent {
    justify-content: flex-start;
  }

  .contractstext {
    font-size: 30px;
  }

  .contractsaddbtn {
    font-size: 30px;
  }

  .filterbtn {
    margin-left: 20px;
  }

  .seecontractbtn {
    font-size: 19px;
  }
}
