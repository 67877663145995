.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* activity section  */

.activity {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.activitycontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activityleft {
  display: flex;
  align-items: center;
}

.textwrapper {
  max-width: 210px;
  margin-left: 12px;
  margin-top: 31px;
  border-right: 2px solid #ffffff;
}

.activitytext {
  font-family: "Hind";
  font-size: 2vw;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
  margin-right: 60px;
}

.buttonwrapper {
  margin-left: 20px;
  margin-top: 25px;
  max-width: 585px;
  height: 75px;
  border-right: 2px solid #ffffff;
}

.buttonwrapper2 {
  margin-left: 20px;
  margin-top: 25px;
  max-width: 472px;
  height: 75px;
  border-right: 2px solid #ffffff;
}

.activitybtn {
  font-family: "Hind";
  font-size: 2vw;
  font-weight: 600;
  line-height: 1px;
  color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  text-transform: uppercase;
  margin-top: 40px;
  margin-right: 20px;
}

.activityright {
  position: relative;
  display: flex;
}

.searchwrapper {
  margin-right: 81px;
}

.searchbar {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 400;
  line-height: 55px;
  color: #0f1521;
  width: 133px;
  height: 50px;
  border-radius: 10px;
  background-color: #616161;
  border: none;
  background-image: url("../../assets/activitypageimages/search.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.searchbar:focus {
  background-image: none;
  background-color: #ffffff;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

/* comments section  */

.accordion {
  max-width: 1920px;
  overflow: hidden;
  background-color: #0f1521;
}

.accordionsection {
  margin-top: 2px;
}

.accordionlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  background-color: #444444;
}

.accordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
  width: 24px;
  height: 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.accordioncontent {
  display: none;
}

.accordioninput {
  display: none;
}

.accordioninput:checked ~ .accordioncontent {
  display: block;
}

.accordioninput:checked ~ .accordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* client  */

.clientinfo {
  display: flex;
  align-items: center;
}

.clientstatus {
  display: flex;
  align-items: center;
}

.clientstatustext {
  height: 100px;
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  background-color: #ffffff;
  color: #0f1521;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-align: center;
}

.clienttext {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.clientname {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.clienttype {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
}

/* inner accordions  */

.inneraccordion {
  max-width: 1920px;
  overflow: hidden;
}

.inneraccordionlabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  height: 60px;
  background-color: #646464;
}

.inneraccordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.inneraccordioncontent {
  background-color: #272c37;
  display: none;
  height: 120px;
}

.inneraccordioninput {
  display: none;
}

.inneraccordioninput:checked ~ .inneraccordioncontent {
  display: block;
}

.inneraccordioninput:checked ~ .inneraccordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* list  */

.listtext {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 600;
  line-height: 55px;
  color: #ffffff;
  margin-left: 35px;
}

.listbox {
  width: 23px;
  height: 23px;
  margin-top: -2px;
  margin-left: 20px;
}

/* list content  */

.comment {
  display: flex;
  align-items: center;
}

.commentinfo {
  width: 200px;
  height: 120px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-right: solid 1px #ffffff;
}

.personname {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
  margin-top: 13px;
  margin-left: 10px;
}

.personjob {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
  margin-left: 10px;
}

.commentcontractname,
.commentcontractdate {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
  margin-left: 10px;
}

.commentwrapper {
  display: flex;
  flex-direction: column;
  margin-left: 23px;
  margin-top: 10px;
}

.commenttitle {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: #ffffff;
  text-decoration: underline;
}

.commenttext {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #ffffff;
}

.likewrapper {
  display: flex;
  margin-left: 23px;
  margin-top: 37px;
}

.rightlike {
  margin-left: 196px;
}

.commentlike {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
}

.likeml {
  margin-left: 15px;
}

/* filter form  */

.filterformcontent {
  position: absolute;
  top: 77px;
  left: -374px;
  background-color: #b4eb54;
  min-width: 700px;
  height: 304px;
  padding: 12px 16px;
  z-index: 1;
}

.period {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.formtext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #0f1521;
}

.periodyear {
  width: 100px;
  height: 50px;
}

.periodother {
  width: 55px;
  height: 50px;
}

.formwrapper {
  display: flex;
  align-items: center;
}

.formcheckboxtext {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #0f1521;
}

.formcheckbox {
  width: 30px;
  height: 30px;
}

.formml {
  margin-left: 20px;
}

.formbigml {
  margin-left: 122px;
}

.savebtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  color: #0f1521;
  width: 180px;
  height: 52px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-left: 271px;
}

@media only screen and (max-width: 1200px) {
  .activitytext,
  .activitybtn {
    font-size: 30px;
  }

  .textwrapper,
  .buttonwrapper,
  .buttonwrapper2 {
    border-right: none;
  }

  .activityleft,
  .activityright {
    flex-direction: column;
  }

  .activity {
    height: 300px;
  }

  .filterbtn {
    margin-top: 127px;
    margin-left: -50px;
  }

  .filterformcontent {
    min-width: 500px;
    top: 265px;
  }

  .inneraccordioncontent {
    height: 140px;
  }

  .listtext {
    font-size: 27px;
  }
}
