.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(
    180deg,
    #0f1521 63.17%,
    #384c2b 107.33%,
    #537132 117.9%,
    #77a03b 127.22%,
    #beff4d 127.23%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
}

.loginFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  background-image: url("../../assets/loginpageimages/logo.png");
  width: 70vw;
  height: 300px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.container a {
  margin-top: 20px;
  color: #b4eb55;
  text-decoration: none;
  font-family: "Hind", sans-serif;
}
