.input {
  background: rgba(29, 38, 56, 0.6);
  box-shadow: inset -3px 1px 20px #1774ff;
  border-radius: 10px;
  color: #fff;
  min-width: 300px;
  border: none;
  padding: 10px;
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-size: 18px;
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  font-family: "Hind", sans-serif;
  opacity: 1; /* Firefox */
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  font-family: "Hind", sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  font-family: "Hind", sans-serif;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}
