/* main page section  */

.contentwrapper {
  max-width: 1850px;
  margin-left: auto;
  margin-right: auto;
}

.maincontent {
  max-width: 1920px;
  height: 1010px;
  background: linear-gradient(
    180deg,
    #0f1521 63.17%,
    #384c2b 107.33%,
    #537132 117.9%,
    #77a03b 127.22%,
    #beff4d 127.23%
  );
}

.mainpage {
  padding-top: 55px;
}

.mainpagewrapper {
  display: flex;
}

.clientsbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b4eb54;
  width: 550px;
  height: 100px;
  text-decoration: none;
}

.clientstext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #b4eb54;
  margin-left: 25px;
  margin-top: 53px;
}

.clientsicon {
  display: flex;
  align-items: center;
  border-left: solid 1px #b4eb54;
  width: 100px;
  height: 100%;
}

.clientsloupe {
  margin-top: 1px;
  margin-left: 24px;
}

.mt {
  margin-top: 15px;
}

.matchesbtn {
  display: flex;
  border: 1px solid #b4eb54;
  width: 550px;
  height: 100px;
  margin-top: 15px;
  text-decoration: none;
}

.matchestext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #b4eb54;
  margin-left: 25px;
  margin-top: 41px;
}

.contractsbtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #b4eb54;
  width: 550px;
  height: 100px;
  margin-top: 15px;
  text-decoration: none;
}

.contractstext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #b4eb54;
  margin-left: 25px;
  margin-top: 53px;
}

.contractsnumber {
  padding: 4px 15px;
  border-radius: 50%;
  font-family: "Hind";
  font-size: 36px;
  font-weight: 500;
  line-height: 34px;
  color: #101521;
  background-color: #ffffff;
  margin-right: 9px;
  margin-bottom: 43px;
}

/* _______________________________________________ */

.dropdown {
  position: relative;
  display: inline-block;
  width: 708px;
  height: 130px;
  background-color: #0e1626;
  box-shadow: -2px -4px 17px 12px #000000 inset;
  margin-left: 35px;
  margin-right: 35px;
  cursor: pointer;
}

.dropdowncontent {
  display: none;
  position: absolute;
  top: 130px;
  left: 0px;
  background-color: #0e1626;
  min-width: 708px;
  height: 770px;
  box-shadow: -2px -4px 17px 12px #000000 inset;
  padding: 12px 13px;
  z-index: 1;
  overflow-y: scroll;
}

.dropdown:hover .dropdowncontent {
  display: block;
}

.dropdownwrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dropdowntext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 500;
  line-height: 69px;
  color: #ffffff;
  margin-top: 20px;
}

.dropdownimg {
  margin-top: -15px;
}

.contenttext {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  width: 671px;
  height: 94px;
  background-color: #878b93;
  margin-bottom: 5px;
}

.contentdate {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 565px;
}

/* ___________________________________________________________ */

.logo {
  margin-top: -50px;
  width: 535px;
  height: 218px;
}

.managerinfo {
  display: flex;
  margin-top: 3px;
}

.avatar {
  box-shadow: -2px -4px 17px 12px #000000 inset;
}

.manager {
  width: 250px;
  height: 350px;
  background-color: #0e1626;
  box-shadow: -2px -4px 17px 12px #000000 inset;
}

.managertext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  color: #b4eb54;
  margin-left: 10px;
}

.exitbtn {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #b4eb54;
  border: 1px solid #b4eb54;
  background-color: transparent;
  width: 196px;
  height: 100px;
  padding: 0;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 278px;
  margin-left: 264px;
}

@media only screen and (max-width: 1200px) {
  .maincontent {
    height: 3000px;
  }

  .mainpagewrapper {
    flex-direction: column;
  }

  .dropdown {
    margin-left: 9px;
    margin-top: 20px;
  }

  .mobilenav {
    margin-left: 10px;
  }

  .mobilesmrtstats {
    margin-top: 854px;
    margin-left: 118px;
  }

  .exitbtn {
    margin-left: 141px;
  }
}
