.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  left: 30px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  width: 300px;
  min-height: 55px;
  padding: 5px;
  cursor: pointer;
}

.containerLight {
  background-color: #ebf1ff;
}

.button {
  cursor: pointer;
  border: none;
  font-weight: 700;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Hind", sans-serif;
}

.span {
  font-family: "Hind";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.spanLight {
  color: black;
}

.eng {
  background-color: transparent;
  color: white;
}

.es {
  background-color: transparent;
  color: white;
}

.buttonContainer {
  display: flex;
  width: 90%;
  align-items: center;
  gap: 25px;
}

.spainFlag {
  background-image: url("../../assets/loginpageimages/spain-flag.png");
  width: 80px;
  height: 34px;
  background-size: cover;
}

.americanFlag {
  background-image: url("../../assets/loginpageimages/american-flag.png");
  width: 56px;
  height: 34px;
  background-size: cover;
}

.languageSwitcherDropDown {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
