.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* employees section  */

.employees {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.employeescontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.employeesleft {
  display: flex;
  align-items: center;
}

.textwrapper {
  width: 225px;
  margin-left: 12px;
  margin-top: 31px;
  border-right: 2px solid #ffffff;
}

.employeestext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
}

.filterscheckboxes {
  display: flex;
}

.filterswrapper {
  margin-left: 10px;
  margin-top: -1px;
  max-width: 909px;
}

.filterstext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  color: #ffffff;
}

.filterscheckboxtext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #ffffff;
}

.filtersmr {
  margin-right: 7px;
}

.filtersbigmr {
  margin-right: 20px;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

.employeesright {
  position: relative;
}

.mobilecheckboxes {
  display: flex;
}

/* employees list section  */

.employeeslist {
  background-color: #0f1521;
  height: 1080px;
  padding-top: 2px;
}

.empllist {
  background-color: #646464;
  overflow-x: scroll;
}

.empldates {
  display: flex;
  align-items: center;
}

.emplinfo {
  display: flex;
  align-items: center;
  margin-left: 42px;
  margin-right: 125px;
}

.emplname {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 600;
  line-height: 55px;
  color: #ffffff;
}

.btnwrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.emplsortbtn {
  width: 30px;
  height: 30px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #646464;
  border: 1px solid #ffffff;
}

.emplradiusl {
  border-radius: 5px 0px 0px 5px;
}

.emplradiusr {
  border-radius: 0px 5px 5px 0px;
}

.empldate {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  color: #ffffff;
}

.emplmlsmall {
  margin-left: 70px;
}

.employee {
  display: flex;
  align-items: center;
  height: 100px;
}

.employeeinfo {
  display: flex;
  flex-direction: column;
  margin-left: 44px;
  margin-right: 153px;
}

.employeename {
  font-family: "Hind";
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
  color: #ffffff;
  width: 122px;
}

.employeeposition {
  font-family: "Hind";
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #ffffff;
}

.employeebtn {
  display: flex;
  min-width: 100px;
  height: 88px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 10px;
}

.employeeml {
  margin-left: 10px;
}

.employeebtngreen {
  background-color: #b4eb54;
}

.employeemessages {
  font-family: "Hind";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #646464;
  margin-left: 8px;
  margin-top: 3px;
}

/* filter form  */

.filterformcontent {
  position: absolute;
  top: 76px;
  left: -868px;
  background-color: #b4eb54;
  min-width: 980px;
  height: 730px;
  padding: 12px 16px;
  z-index: 2;
  border-radius: 0px 0px 0px 5px;
}

.filterform {
  margin-top: 10px;
}

.formoption {
  width: 260px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.formbigoption {
  width: 495px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.formtext {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #0f1521;
}

.periodyear {
  width: 60px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.periodother {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 5px;
}

.formwrapper {
  display: flex;
  align-items: center;
}

.formcheckboxtext {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #545454;
}

.formcheckbox {
  width: 30px;
  height: 30px;
}

.formml {
  margin-left: 30px;
}

.formtextml {
  margin-left: 10px;
}

.formiconml {
  margin-left: 20px;
}

.formviewml {
  margin-left: 110px;
}

.formperiodml {
  margin-left: 95px;
}

.formdateml {
  margin-left: 10px;
}

.formgroupingml {
  margin-left: 64px;
}

.formresultsml {
  margin-left: 91px;
}

.formdepartmentsml {
  margin-left: 29px;
}

.formsectionsml {
  margin-left: 77px;
}

.formcareersml {
  margin-left: 86px;
}

.formemployeesml {
  margin-left: 51px;
}

.formtemplatebtnml {
  margin-left: 9px;
}

.formsavebtnml {
  margin-left: 34px;
}

.hideinfo {
  display: flex;
  align-items: center;
  margin-left: 164px;
  margin-top: 2px;
}

.formhr {
  border: 1px solid #000000;
  width: 98%;
}

.pageoption {
  width: 70px;
  height: 30px;
  border-radius: 5px;
  border: none;
  margin-left: 37px;
}

.buttonwrapper {
  display: flex;
  margin-left: 328px;
}

.savebtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #545454;
  width: 102px;
  height: 40px;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
}

.templatebtn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #545454;
  width: 225px;
  height: 40px;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 5px;
}

.mobileforms {
  display: flex;
}

/* message  */

.message {
  position: absolute;
  top: 182px;
  left: 736px;
  width: 1167px;
  height: 890px;
  z-index: 1;
  background-color: #ffffff;
  /* border-radius: 15px, 0px, 0px, 15px; */
}

.messagewrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.messagename {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 500;
  line-height: 41px;
  color: #0f1521;
  margin-left: 30px;
}

.messagehr {
  border: 2px solid #636363;
  width: 98%;
}

.messagebtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
}

.messagenumber {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  margin-left: 30px;
  color: #0f1521;
}

.messagedate {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #0f1521;
  margin-left: 50px;
}

.messagewrapper2 {
  display: flex;
  align-items: center;
}

.messagesentby {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #939393;
  margin-left: 30px;
}

.messagemt {
  margin-top: 100px;
}

.messagetext {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .employeestext {
    font-size: 32px;
  }

  .textwrapper {
    border-right: none;
  }

  .filterstext,
  .filterscheckboxtext {
    font-size: 19px;
  }

  .employees {
    height: 200px;
  }

  .filterscheckboxes {
    flex-direction: column;
  }

  .message {
    margin-top: 101px;
    width: 583px;
    left: 100px;
  }

  .mobileforms {
    flex-direction: column;
  }

  .mobileformssec {
    margin-top: 20px;
    margin-left: 22px;
  }

  .mobileformwrapper {
    align-items: flex-start;
    flex-direction: column;
  }

  .mobileformwrappersec {
    margin-top: 20px;
  }

  .buttonwrapper {
    margin-left: 2px;
  }

  .filterformcontent {
    margin-top: 48px;
    min-width: 626px;
    height: 850px;
    left: -538px;
  }

  .formbigoption {
    width: 260px;
  }
}
