.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* contracts confirmation section  */

.contractscfm {
  max-width: 1920px;
  background-color: #2a2a2a;
  height: 100px;
}

.contractscfmcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contractscfmleft {
  width: 100%;
  display: flex;
  align-items: center;
}

.textwrapper {
  width: 435px;
  margin-left: 16px;
  margin-top: 31px;
  border-right: 2px solid #ffffff;
  text-wrap: nowrap;
}

.contractscfmtext {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 700;
  line-height: 69px;
  color: #ffffff;
  margin-right: 10px;
}

.searchwrapper {
  max-width: 1190px;
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.searchbar {
  font-family: "Hind";
  font-size: 32px;
  font-weight: 400;
  line-height: 55px;
  color: #0f1521;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #616161;
  border: none;
  background-image: url("../../assets/contractscfmpageimages/search.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.searchbar:focus {
  background-image: none;
  background-color: #ffffff;
}

.filterbtn {
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #2a2a2a;
  margin-right: 46px;
}

/* cfminfo section  */

.accordion {
  max-width: 1920px;
  overflow: hidden;
  background-color: #0f1521;
}

.accordionsection {
  margin-top: 2px;
}

.accordionlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  background-color: #444444;
}

.accordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 65px;
  width: 24px;
  height: 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.accordioncontent {
  display: none;
}

.accordioninput {
  display: none;
}

.accordioninput:checked ~ .accordioncontent {
  display: block;
}

.accordioninput:checked ~ .accordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* client  */

.clientinfo {
  display: flex;
  align-items: center;
}

.clientstatus {
  display: flex;
  align-items: center;
}

.clientstatustext {
  height: 100px;
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  background-color: #ffffff;
  color: #0f1521;
  writing-mode: vertical-rl;
  text-transform: uppercase;
  text-align: center;
}

.clienttext {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.clientname {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.clienttype {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #ffffff;
}

/* inner accordions  */

.inneraccordion {
  max-width: 1920px;
  overflow: hidden;
}

.inneraccordionlabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: background 0.1s;
  height: 80px;
  background-color: #646464;
}

.inneraccordionlabel::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  width: 12px;
  height: 6px;
  background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFFFFF;" /></svg>');
  background-size: contain;
  transition: transform 0.4s;
}

.inneraccordioncontent {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
  background-color: #272c37;
  display: none;
  height: 160px;
}

.inneraccordioninput {
  display: none;
}

.inneraccordioninput:checked ~ .inneraccordioncontent {
  display: block;
}

.inneraccordioninput:checked ~ .inneraccordionlabel::after {
  transform: translateY(-50%) rotate(0.5turn);
}

/* contract  */

.contract {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
}

.contractname,
.date {
  font-family: "Hind";
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: #ffffff;
}

.contractbuttons {
  display: flex;
  margin-right: 110px;
}

.btn {
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  color: #ffffff;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #646464;
  border: solid 1px #ffffff;
  border-radius: 10px;
  margin-right: 10px;
}

.approve {
  width: 119px;
  height: 36px;
}

.disapprove {
  width: 161px;
  height: 36px;
}

.seecontract {
  width: 240px;
  height: 36px;
}

.status {
  display: inline-block;
  font-family: "Hind";
  font-size: 24px;
  font-weight: 700;
  line-height: 41px;
  width: 100px;
  height: 36px;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .contractscfmtext {
    font-size: 30px;
  }

  .contractscfmcontent {
    justify-content: flex-start;
  }

  .filterbtn {
    margin-left: 50px;
  }

  .accordionlabel {
    justify-content: flex-start;
  }

  .accordionlabel::after {
    right: 40px;
  }

  .inneraccordionlabel {
    height: 100px;
    justify-content: flex-start;
  }

  .contract {
    margin-left: 30px;
  }

  .contractbuttons {
    flex-wrap: wrap;
    margin-left: 130px;
  }

  .seecontract,
  .status {
    margin-top: 10px;
  }

  .btn {
    font-size: 19px;
  }

  .status {
    font-size: 19px;
  }
}
