.contentwrapper {
  max-width: 1860px;
  margin-left: auto;
  margin-right: auto;
}

/* header */

.matchesheader {
  max-width: 1920px;
  background-color: #0f1521;
  height: 80px;
}

.matchesheaderwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 198px;
  height: 74px;
}

.buttons {
  margin-right: 20px;
}

.menubtn,
.exitbtn {
  font-family: "Hind";
  font-size: 40px;
  font-weight: 600;
  line-height: 57px;
  color: #b4eb54;
  border: 1px solid #b4eb54;
  background-color: #0f1521;
  width: 150px;
  height: 50px;
  padding: 0;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.homebtn {
  display: inline-block;
  font-family: "Hind";
  font-size: 40px;
  font-weight: 600;
  line-height: 57px;
  color: #b4eb54;
  border: 1px solid #b4eb54;
  background-color: #0f1521;
  width: 150px;
  height: 50px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}

.headermr {
  margin-right: 10px;
}

.headerml {
  margin-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .matchesheaderwrapper {
    justify-content: flex-start;
  }

  .homebtn,
  .menubtn,
  .exitbtn {
    font-size: 30px;
  }
}
